<template>
  <h1>Aktuelles Wetter</h1>
  <!--  <h2>akutelles Wetter</h2>-->
  <!--  <p>Leichter Regen</p>-->
  <table class="center">
    <thead>
    <tr>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td>Temperatur</td>
      <td>{{ weatherData.temp }}</td>
      <td>°C</td>
    </tr>
    <tr>
      <td>Luftfeuchtigkeit</td>
      <td>{{ weatherData.humidity }}</td>
      <td>%</td>
    </tr>
    <tr>
      <td>Luftdruck</td>
      <td>{{ weatherData.pressure }}</td>
      <td>hPa</td>
    </tr>
    <tr>
      <td>Regen</td>
      <td>{{ weatherData.rain }}</td>
      <td>mm</td>
    </tr>
    <tr>
      <td>Wind Avg.</td>
      <td>{{ weatherData.wind_avg }}</td>
      <td>m/s</td>
    </tr>
    <tr>
      <td>Wind Max</td>
      <td>{{ weatherData.wind_max }}</td>
      <td>m/s</td>
    </tr>
    <tr>
      <td>Windrichtung</td>
      <td>
        <span :style="userStyle" class="compass center"></span>
      </td>
      <td></td>
    </tr>
    </tbody>
  </table>

  <p class="center text-center">Stand: {{ weatherData.time_str }}</p>
</template>

<script>
export default {
  name: 'WeatherDisplay',
  props: {},
  data: function () {
    return {
      weatherData: {}
    }
  },

  created() {
    this.getData()
  },
  methods: {
    async getData() {
      let response = await fetch("https://wapi.marhil.de/now");
      this.weatherData = await response.json();
      // JSON responses are automatically parsed.
    },
  },
  computed: {
    userStyle () {
      return {
        '--r': this.weatherData.wind_dir
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
