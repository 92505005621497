<template>
  <div id="main" class="center">
    <Suspense>
      <WeatherDisplay />
    </Suspense>
    <Suspense>
      <WeatherHistory />
    </Suspense>
  </div>
</template>

<script>
import WeatherDisplay from './components/WeatherDisplay.vue'
import WeatherHistory from './components/WeatherHistory.vue'

export default {
  name: 'App',
  components: {
    WeatherDisplay,
    WeatherHistory
  },

}
</script>

<style>
  @import './assets/weather.css';
</style>
