<template>
  <div id="history" class="center">
    <a v-for="b in buttonList" :href=b.link :key=b.link>{{ b.name }}</a>
    <Line id="test" v-if="loaded" :options="chartOptions" :data="chartData"></Line>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { CategoryScale, Chart, LinearScale, PointElement, LineElement } from "chart.js";

export default {
  name: "WeatherHistory",
  components: {
    Line,
    // eslint-disable-next-line vue/no-unused-components
    CategoryScale, LinearScale, Chart, PointElement, LineElement
  },
  data: function () {
    return {
      buttonList: [
        {link: "?hist=12h", name: "12 Stunden"},
        {link: "?hist=1d", name: "24 Stunden"},
        {link: "?hist=2d", name: "48 Stunden"},
        {link: "?hist=1w", name: "7 Tage"},
        {link: "?hist=1m", name: "1 Monat"},
      ],
      weatherData: [{}],
      loaded: false,
      chartOptions: {
        responsive: true
      },
      chartData: {}
    }
  },
  created() {
      Chart.register(CategoryScale)
      Chart.register(LinearScale)
      Chart.register(PointElement)
    Chart.register(LineElement)
      this.getData()
  },
  methods: {
    async getData() {
      let params = new URLSearchParams(window.location.search.substring(1));
      let hist = params.get("hist"); // returns the number 123
      console.log(hist);
      hist = hist !== null ? hist : "1d";

      let response = await fetch("https://wapi.marhil.de/rain/" + hist);
      this.weatherData = await response.json();
      this.setChartData();
      this.loaded = true;
      // JSON responses are automatically parsed.
    },
    setChartData() {
      this.chartData.labels = this.weatherData.map( row => row.time_str )
      this.chartData.datasets = [{
        label: "Test",
        data: this.weatherData.map( row => row.temp )
      }];
    },
  },
}
</script>

<style scoped>

</style>